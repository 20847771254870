import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import styles from "./styles/JourneyScreen.module.css";

const tasks = [
  { number: 1, title: "Úloha 1: Hľadačský začiatok", description: "Na začiatok niečo ľahké. Nájdi smer ktorým sa vidáme, nájdeš ho tam kde máme uložené naše spomienky.", code: "Park", nextInstructionsTitle: "Ďalej: ", nextInstructions: "Nasleduj ružovú." },
  { number: 2, title: "Úloha 2: Čarovný večer", description: "Miesto, kde náš príbeh našiel svoj počiatok, kde kúzlo nahradilo rutinu, kde všetko zmenili prvé slová a pohľady.", code: "Pohoda", nextInstructionsTitle: "Ďalej: ", nextInstructions: "Neboj sa liezť." },
  { number: 3, title: "Úloha 3: Vyšší dotyk", description: "Medzi šumom lístia a tajomstvom skalných strážcov sa skrýva miesto, kde dve cesty sa prepletajú v jednu. Nájdi heslo, ktoré nás opäť privedie bližšie k cieľu.", code: "Salatín" },
  { number: 4, title: "Úloha 4: Pohľad, ktorý spája horizonty", description: "Miesto, kde sa otvára opona nekonečných príbehov a panoráma snov sa rozprestiera pred tebou, je ukrytý kľúč k ďalšiemu tajomstvu. A leží nám ako na dlani.", code: "Praha", nextInstructions: "" },
  {
    number: 5,
    title: "Úloha 5: Strážca tajomstiev",
    description:
      "Na púti za odkrytím sveta, tam, kde umenie stretáva večnosť a nehybní strážcovia rozprávajú príbehy minulosti, nájdeš kúsok sveta, o ktorom snívaš. Ukazuje smer kde odpočíva dar, ktorý ti prináša vôňu vzdialenej zeme, plnej života, radosti a nekonečnej energie. Pozeraj sa mu zoči voči, potom sa pozri rovno na mesto a nájdi indíciu.",

    nextInstructionsTitle: "Hint pre ďalšiu úlohu: ",
    nextInstructions: "Google Lens",
    code: "Brazília",
  },
  {
    number: 6,
    title: "Úloha 6: Strážia ho anjeli",
    description:
      "Tam, kde sa legendy tkané z kamenných pilierov stretávajú s osobnými snami, skrýva sa postava, ktorá je mostom medzi svetmi a časmi. Tento strážca, chránený anjelmi, stojí nad smrťou, je odetý do odkazu vzdialenej zeme plnej vášne, umenia a melodického jazyka, ktorý znie ako hudba pre tvoje srdce, je zároveň kľúčom k rozlúšteniu hádanky.",
    code: "Španielsko",
  },
  {
    number: 7,
    title: "Úloha 7: Sladká stopa",
    description: "V labirinte mestských uličiek sa skrýva miesto, kde sladkosť spomienok sa mieša so žltými pochúťkami. Pátraj po úkryte, ktorý v sebe ukrýva kľúč k dobrodružstvu na skalnatom výbežku, známom svojimi zvedavými obyvateľmi ktorí túto pochuťku veľmi radi konzumujú.",
    code: "Gibraltár",
    nextInstructions: "",
  },
  {
    number: 8,
    title: "Úloha 8: Začiatok aj koniec",
    description: "Nájdi miesto, kde sa skrýva viac než len čas ukazujúci orloj. V jednej z budov, ktorá bola svedkom nielen našich prvých spoločných chvíľ v tomto meste, ale verím že bude svedkom aj mnohých ďalších príbehov, ktoré ešte len prídu.",
    code: "Skauťák",
    nextInstructions: "",
  },
];
//142 235 371 451 511 666 788 824

function JourneyScreen() {
  const [currentTask, setCurrentTask] = useState(null);
  const [enteredCode, setEnteredCode] = useState("");
  const [message, setMessage] = useState("");
  const [celebrate, setCelebrate] = useState(false);
  const [showEndGameDialog, setShowEndGameDialog] = useState(false);
  const correctFinalWord = "BUDAPEST".split("");
  const [finalWord, setFinalWord] = useState(
    correctFinalWord.map(() => ({
      letter: "",
      correct: false,
      error: false,
    }))
  );

  const navigate = useNavigate();

  const handleFinalWordInput = (input, index) => {
    const inputLetter = input.toUpperCase();
    const isCorrect = inputLetter === correctFinalWord[index];

    // Update the state of the final word
    const updatedFinalWord = finalWord.map((item, i) => {
      if (i === index) {
        return { ...item, letter: inputLetter, correct: isCorrect, error: !isCorrect };
      }
      return item;
    });

    setFinalWord(updatedFinalWord);

    // If there's an error, clear the input after a short delay
    if (!isCorrect && inputLetter) {
      setTimeout(() => {
        setFinalWord(
          finalWord.map((item, i) => {
            if (i === index) {
              return { ...item, letter: "", error: false };
            }
            return item;
          })
        );
      }, 1000); // Set timeout to 1 second
    }
  };

  const startGame = () => {
    setCurrentTask(0); // Začatie hry nastavením prvej úlohy
  };

  const verifyCodeAndAdvance = (taskNumber) => {
    if (tasks[taskNumber].code === enteredCode) {
      setCurrentTask(currentTask + 1);
      setEnteredCode("");
      setMessage("");
    } else {
      setMessage("Ajaaj 😔, skús ešte raz.");
    }
  };

  const handleInputChange = (e) => {
    setEnteredCode(e.target.value);
  };

  const handleEndGameClick = () => {
    setShowEndGameDialog(true);
  };

  const confirmEndGame = () => {
    navigate("/");
  };

  const cancelEndGame = () => {
    setShowEndGameDialog(false);
  };

  return (
    <div className={styles.journeyScreen}>
      {currentTask !== null && (
        <>
          <div className={styles.fixedHeader}>
            <div className={styles.title}>Abejkina narodeninová cesta</div>
          </div>
          {/* <div className={styles.titleGradient}></div> */}
        </>
      )}
      {currentTask !== null && <div className={styles.titleGradient}></div>}
      <div className={styles.taskContainer}>
        {currentTask === null ? (
          <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={styles.introContent}>
            <h2>Ahoj Abejka!</h2>
            <div className={styles.introSubtitle}>Si pripravená na tvoju narodeninovú cestu?</div>
            <div className={styles.intro}>
              {" "}
              Ak áno, poďme na to. Avšak pre začiatok by sme mali byť <span className={styles.highlight}>spolu</span> na našej <span className={styles.highlight}>izbe</span> a mať troška <span className={styles.highlight}>času</span>, a možno by bolo fajn ak by bolo pred zotmením.
            </div>
            <div>
              <button onClick={startGame} className={styles.startButton}>
                Začať hru, juchuu 🥳
              </button>
              <button onClick={confirmEndGame} className={styles.startButtonRight}>
                Nespĺňam požiadavky 😔
              </button>
            </div>{" "}
          </motion.div>
        ) : (
          tasks.map(
            (task, index) =>
              currentTask >= index && (
                <motion.div key={task.number} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={styles.taskCard}>
                  <h2 className={styles.taskTitle}>{task.title}</h2>
                  <p className={styles.taskDescription}>{task.description}</p>
                  {currentTask === index ? (
                    <>
                      <input type="text" value={enteredCode} onChange={handleInputChange} placeholder="Tu zadaj heslo" className={styles.inputField} />
                      <button onClick={() => verifyCodeAndAdvance(index)} className={styles.submitButton}>
                        Odomkni ďalšiu úlohu
                      </button>

                      <button onClick={handleEndGameClick} className={styles.endButton}>
                        Ukončiť hru
                      </button>
                    </>
                  ) : (
                    <>
                      <div className={styles.codeReveal}>{task.code}</div>
                      {task.nextInstructions && (
                        <div className={styles.nextInstructions}>
                          <span style={{ fontWeight: "bold" }}>{task.nextInstructionsTitle}</span>
                          {task.nextInstructions}
                        </div>
                      )}
                    </>
                  )}
                  {showEndGameDialog && (
                    <div className={styles.overlay}>
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }} className={styles.messageCard}>
                        <p>Si si istá, že chceš naozaj ukončiť hru?</p>
                        <button onClick={confirmEndGame} className={styles.yesButton}>
                          Áno, ukončiť
                        </button>
                        <button onClick={cancelEndGame} className={styles.noButton}>
                          Nie, pokračovať v hre
                        </button>
                      </motion.div>
                    </div>
                  )}
                </motion.div>
              )
          )
        )}
        {currentTask >= tasks.length && (
          <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }} className={styles.finalTaskCard}>
            <h2 className={styles.finalTitle}>Všetko najlepšie Abejka 🥳💝</h2>
            <p className={styles.finalSubtitle}>Zvládla si to! Ďakujem že si tu so mnou, ďakujem že môžem byť po tvojom boku. A teraz prosím príjimi pozvanie na malý drink. 🍻😄</p>
            {!celebrate && (
              <button className={styles.submitButton} onClick={() => setCelebrate(true)}>
                Stlač ma až keď budete sedieť.
              </button>
            )}
            {celebrate && (
              <>
                <p className={styles.finalInstructions}>Predsa ťa ešte čaká jedna úloha, nájdi súvislosť medzi šifrou čo sme dostali a heslami na ktoré si počas hry prišla, a vylúskaj finálne slovo.</p>
                <div className={styles.finalWordContainer}>
                  {finalWord.map((entry, index) => (
                    <input key={index} type="text" maxLength="1" value={entry.letter} onChange={(e) => handleFinalWordInput(e.target.value, index)} className={`${styles.finalInputField} ${entry.correct ? styles.correct : entry.error ? styles.incorrect : ""}`} disabled={entry.correct} />
                  ))}
                </div>
                {finalWord.every((entry) => entry.correct) && (
                  <>
                    <p className={styles.finalCongratulations}>Juchuu! Uhádla si to, pôjdeš so mnou na výlet láska? 🚄</p>{" "}
                    <button onClick={() => handleEndGameClick()} className={styles.submitButton}>
                      Späť na úvodnú stránku
                    </button>
                  </>
                )}
              </>
            )}
          </motion.div>
        )}
      </div>
      {message && (
        <div className={styles.overlay}>
          <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }} className={styles.messageCard}>
            <p>{message}</p>
            <button onClick={() => setMessage("")} className={styles.closeButton}>
              Skúsim! 🙌
            </button>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default JourneyScreen;
