import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IntroScreen from "./screens/IntroScreen";
import PlayScreen from "./screens/PlayScreen";
import HappyBirthday from "./screens/HappyBirthday";
import JourneyScreen from "./screens/JourneyScreen";

function App() {
  const [background, setBackground] = useState("");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<IntroScreen onBackgroundChange={setBackground} />} />
        <Route path="/play" element={<PlayScreen />} />
        <Route path="/journey" element={<JourneyScreen />} />
        <Route path="/happy-birthday" element={<HappyBirthday background={background} />} />
      </Routes>
    </Router>
  );
}

export default App;
