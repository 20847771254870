import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { uniqueCardsArray } from "../components/CardsTypes";
import "./styles/PlayScreen.css";

function PlayScreen() {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setCards(shuffleAndPrepareCards());
  }, []);

  const shuffleAndPrepareCards = () => {
    const doubledCards = [...uniqueCardsArray, ...uniqueCardsArray].map((card, index) => ({ ...card, id: index, flipped: false })).sort(() => Math.random() - 0.5);
    return doubledCards;
  };

  const handleCardClick = (index) => {
    if (disabled) return;
    const newCards = [...cards];
    if (!newCards[index].flipped) {
      newCards[index].flipped = true;
      setCards(newCards);
      choiceOne ? setChoiceTwo(newCards[index]) : setChoiceOne(newCards[index]);
    }
  };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.type === choiceTwo.type) {
        resetTurns();
      } else {
        setTimeout(() => {
          const newCards = cards.map((card) => {
            if (card.id === choiceOne.id || card.id === choiceTwo.id) {
              return { ...card, flipped: false };
            }
            return card;
          });
          setCards(newCards);
          resetTurns();
        }, 1000);
      }
    }
  }, [choiceOne, choiceTwo, cards]);

  const resetTurns = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  const resetGame = () => {
    setCards(shuffleAndPrepareCards());
    setTurns(0);
    setChoiceOne(null);
    setChoiceTwo(null);
    setDisabled(false);
  };

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <div className="playScreen">
      <div className="title">
        Nájdi rovnakého <span className="jojo">Jožinka</span> (ale aj nás 😄)
      </div>
      <div className="card-grid">
        {cards.map((card, index) => (
          <div className={`card ${card.flipped ? "flipped" : ""}`} key={card.id} onClick={() => handleCardClick(index)}>
            <img src={card.image} alt="Memory Card" className={card.flipped ? "visible" : "hidden"} />
            {console.log(card.flipped)}
          </div>
        ))}
      </div>
      <p>
        Počet otočení: <span className="count">{turns}</span>
      </p>
      <div className="game-controls">
        <button onClick={resetGame}>Reštartuj hru</button>
        <button onClick={navigateHome}>Naspäť</button>
      </div>
    </div>
  );
}

export default PlayScreen;
