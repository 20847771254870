import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles/Intro.css";

function IntroScreen({ onBackgroundChange }) {
  const navigate = useNavigate();

  const navigateToHappyBirthday = (bgColor) => {
    onBackgroundChange(bgColor);
    navigate("/happy-birthday");
  };

  const navigateToPlay = () => {
    navigate("/play");
  };
  const navigateToIourney = () => {
    navigate("/journey");
  };

  return (
    <div className="App" style={{ backgroundColor: "#3d3d3d" }}>
      <div>
        <ul>
          <li>V</li>
          <li>i</li>
          <li>t</li>
          <li>a</li>
          <li>j</li>
          <li> </li>
          <li>n</li>
          <li>a</li>
          <li></li>
          <li>t</li>
          <li>v</li>
          <li>o</li>
          <li>j</li>
          <li>e</li>
          <li>j</li>
          <li></li>
          <li>o</li>
          <li>s</li>
          <li>l</li>
          <li>a</li>
          <li>v</li>
          <li>e</li>
          <li>!</li>
        </ul>
        <div className="lubim">
          Ľúbim ťa <span className="abejka">Abejka</span>
        </div>
      </div>
      <div className="row-btns">
        <button className="button-23-pink" onClick={() => navigateToHappyBirthday("#f7cac9")}>
          Ružová
        </button>
        <button className="button-23-yellow" onClick={() => navigateToHappyBirthday("#f7b801")}>
          Žltá
        </button>
      </div>{" "}
      <button className="button-23-purple" onClick={() => navigateToPlay()}>
        Chcem sa zahrať
      </button>
      <button className="button-23-green" onClick={() => navigateToIourney()}>
        Chcem začať moju narodeninovú cestu
      </button>
      <div className="bottom-text">
        tvoj <span className="martin">Martin</span>
      </div>
    </div>
  );
}

export default IntroScreen;
