export const uniqueCardsArray = [
  {
    type: "svadba",
    image: require(`./card/1.jpg`),
    backImage: require(`./card/back.jpg`),
  },
  {
    type: "rim",
    image: require(`./card/2.jpg`),
    backImage: require(`./card/back.jpg`),
  },
  {
    type: "norsko",
    image: require(`./card/3.jpg`),
    backImage: require(`./card/back.jpg`),
  },
  {
    type: "lezi",
    image: require(`./card/4.jpg`),
    backImage: require(`./card/back.jpg`),
  },
  {
    type: "oddych",
    image: require(`./card/5.jpeg`),
    backImage: require(`./card/back.jpg`),
  },
  {
    type: "divnepozera",
    image: require(`./card/6.jpg`),
    backImage: require(`./card/back.jpg`),
  },
];
