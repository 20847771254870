import React, { useEffect, useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { confetti } from "tsparticles-confetti";
import { confettiOptions } from "../components/confettiOptions";

function HappyBirthday({ background }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [isZoomIn, setIsZoomIn] = useState(false);
  const [isZoomAbejka, setIsZoomAbejka] = useState(false);
  const navigate = useNavigate();

  const navigateToIntro = () => {
    navigate("/");
  };

  var audio = new Audio(require("../audio/play.mp3"));

  const playAudioForDuration = (duration = 60000) => {
    audio.play().then(() => {
      setIsPlaying(true);
    });

    // Definujte funkciu, ktorá spustí konfety
    const repeatConfetti = () => {
      const x = Math.floor(Math.random() * 101);
      const y = Math.floor(Math.random() * 101);
      confetti("tsparticles", confettiOptions(x, y));
    };

    const confettiInterval = setInterval(repeatConfetti, 5000);

    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
      clearInterval(confettiInterval);
      setIsPlaying(false);
    }, duration);
  };

  useEffect(() => {
    (async () => {
      await confetti("tsparticles", confettiOptions(50, 50));
    })();
  }, []);

  const handleClick = async (event) => {
    const x = (event.clientX / window.innerWidth) * 100;
    const y = (event.clientY / window.innerHeight) * 100;
    await confetti("tsparticles", confettiOptions(x, y));
  };

  const handleButtonClick = () => {
    playAudioForDuration();
  };

  const handleRotate = () => {
    setIsRotated(true);
    setTimeout(() => {
      setIsRotated(false);
    }, 600);
  };

  const handleZoom = () => {
    setIsZoomIn(true);
    setTimeout(() => {
      setIsZoomIn(false);
    }, 600);
  };

  const handleZoomAbejka = () => {
    setIsZoomAbejka(true);
    setTimeout(() => {
      setIsZoomAbejka(false);
    }, 600);
  };

  return (
    <div className="App" style={{ backgroundColor: background }} onClick={handleClick}>
      <div onClick={handleZoom} className={isZoomIn ? "cat-corner top-left-cat zoom" : "cat-corner top-left-cat"}></div>
      <div onClick={handleRotate} className={isRotated ? "cat-corner top-right-cat rotate" : "cat-corner top-right-cat"}></div>
      <div className="text-wrapper">
        <span className="dancing-font">Všetko najlepšie k narodeninám</span>
        <span className="love">Láska</span>
      </div>
      <button disabled={isPlaying} onClick={handleButtonClick} className="button-23">
        Zahraj mi ♫
      </button>
      <div className="cat-corner bottom-left-cat"></div>
      <div onClick={handleZoomAbejka} className={isZoomAbejka ? "cat-corner bottom-middle-cat zoomAbejka" : "cat-corner bottom-middle-cat"}></div>
      <div onClick={navigateToIntro} className="cat-corner bottom-right-cat"></div>
    </div>
  );
}

export default HappyBirthday;
