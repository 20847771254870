export function confettiOptions(x, y) {
  return {
    angle: 90,
    count: 500,
    position: {
      x: x,
      y: y,
    },
    spread: 360,
    ticks: 100,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    shapes: ["heart"],
    colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
    scalar: 2,
    zIndex: 100,
    disableForReducedMotion: true,
  };
}
